import React from 'react'
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react'

export const onServiceWorkerUpdateReady = () => window.location.reload()

// const onRedirectCallback = appState => {
//   navigate(appState?.returnTo ?? window.location.pathname, { replace: true })
// }

export const wrapRootElement = ({ element }) => {
  const Root = () => (
    <>
      <div id='modal-root' />
      {element}
    </>
  )
  const ProtectedElement = withAuthenticationRequired(Root, {
    onRedirecting: () => <>Redirecting to login...</>,
    returnTo: window.location.href,
  })

  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH_0_DOMAIN}
      clientId={process.env.GATSBY_AUTH_0_CLIENT_ID}
      audience={process.env.GATSBY_AUTH_0_AUDIENCE}
      redirectUri={process.env.GATSBY_AUTH0_REDIRECT_URL}
      scope='openid proile email'
      cacheLocation='localstorage'
      useRefreshTokens
    >
      <ProtectedElement />
    </Auth0Provider>
  )
}
